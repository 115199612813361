/* Circle group */
.firstCircle:hover {
  transform: scale(1.1);
}
.secondCircle:hover {
  transform: scale(1.1);
}
.thirdCircle:hover {
  transform: scale(1.1);
}

/* .firstCircleSm {
  transform: translate(-60px, 20px);
}
.secondCircleSm {
  transform: translate(100px, -105px);
}
.thirdCircleSm {
  transform: translate(-25px, -164px);
} */
