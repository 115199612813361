:root {
  --primary-color: #54d1dc;
  --secondary-color: #ff6c6d;
  --tertiary-color: #8d8d8d;
  --light-gray: #c4c4c4;
  --background-color: #f9f9f9;
  --container-color: #fff;
  --border-radius: 3px;
}

body {
  margin: 0;
  background-color: var(--background-color);
  font-family: 'Poppins', sans-serif, -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid var(--primary-color);
}

.ant-radio-button-wrapper:hover {
  color: var(--primary-color);
}

.ant-select {
  outline: none;
  border-radius: var(--border-radius);

  margin: 0.5rem;
  width: 100%;
}

.ant-select placeholder {
  color: #b0b0b0;
}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input)
  .ant-select-selector:hover {
  border-color: var(--primary-color);
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  background-color: var(--primary-color);
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: var(--primary-color) !important;
  border: 1px solid var(--primary-color) !important;
}

.container {
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

.pac-container:after {
  background-image: none !important;
  height: 0px;
}
